var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.validateForm($event)
        }
      }
    },
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("b-card-header", { staticClass: "pb-50" }, [
            _c("h5", [_vm._v(" Permissions ")])
          ]),
          _c(
            "b-card-body",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Template Name",
                            "label-for": "templatename"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "templatename",
                              readonly: true,
                              name: "templatename"
                            },
                            model: {
                              value: _vm.templatename,
                              callback: function($$v) {
                                _vm.templatename = $$v
                              },
                              expression: "templatename"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("b-table", {
            staticClass: "mb-0",
            attrs: { striped: "", responsive: "", items: _vm.permissionsData },
            scopedSlots: _vm._u([
              {
                key: "cell(module)",
                fn: function(data) {
                  return [_vm._v(" " + _vm._s(data.value) + " ")]
                }
              },
              {
                key: "cell()",
                fn: function(data) {
                  return [
                    _c("b-form-checkbox", {
                      attrs: {
                        id: data.value.name,
                        name: data.value.name,
                        checked: data.value.value,
                        value: data.value.checkedvalue,
                        "unchecked-value": data.value.uncheckedvalue
                      },
                      on: {
                        change: function($event) {
                          return _vm.updatePermissions()
                        }
                      },
                      model: {
                        value: _vm.selected,
                        callback: function($$v) {
                          _vm.selected = $$v
                        },
                        expression: "selected"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c(
            "b-card-body",
            [
              _c(
                "b-row",
                [
                  _vm.$can("update", "abilitiestemplates")
                    ? _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mb-1 mb-sm-0 mr-0 mr-sm-1",
                              attrs: {
                                variant: "primary",
                                block: "",
                                type: "submit"
                              }
                            },
                            [_vm._v(" Save Changes ")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$can("delete", "abilitiestemplates")
                    ? _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mb-1 mb-sm-0 mr-0 mr-sm-1",
                              attrs: { variant: "primary", block: "" },
                              on: { click: _vm.deleteTemplate }
                            },
                            [_vm._v(" Delete Template ")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }